import React from "react";
import { FaUser } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

const Comment = ({ comment }) => {
  // Function to highlight mentions in text
  const highlightMentions = (text) => {
    const parts = text.split(/(@\w+)/g);
    return parts.map((part, i) => {
      if (part.match(/@\w+/)) {
        return <span key={i} className="text-blue-500 font-medium">{part}</span>;
      }
      return part;
    });
  };

  return (
    <div className="flex items-start mb-4">
      <div className="mr-3">
        <FaUser className="w-8 h-8 text-gray-400" />
      </div>
      <div className="flex-grow">
        <div className="flex items-center mb-1">
          <span className="font-semibold text-sm mr-2">User {comment.authorId}</span>
          <span className="text-xs text-gray-500">
            {comment.createdAt.toLocaleString()}
          </span>
        </div>
        <div className="prose prose-sm max-w-none text-gray-700">
          <ReactMarkdown components={{
            p: ({children}) => <p>{highlightMentions(children.toString())}</p>
          }}>
            {comment.content.text}
          </ReactMarkdown>
        </div>
        {comment.isResolved && (
          <span className="text-xs text-green-600 mt-1">Resolved</span>
        )}
        {comment.visibility !== "public" && (
          <span className="text-xs text-gray-500 mt-1">({comment.visibility})</span>
        )}
      </div>
    </div>
  );
};

const CommentSection = ({
  comments,
  onAddComment,
  onCommentChange,
  newComment,
}) => {
  return (
    <div className="mt-4">
      <div className="flex mb-4 items-start">
        <div className="mr-3">
          <FaUser className="w-8 h-8 text-gray-400" />
        </div>
        <div className="flex-grow">
          <textarea
            value={newComment}
            onChange={onCommentChange}
            className="w-full p-2 mb-2 border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md placeholder-gray-400 text-gray-700"
            rows="2"
            placeholder="Leave a comment..."
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                onAddComment();
              }
            }}
          />
          <button
            onClick={onAddComment}
            className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded text-sm"
          >
            Comment
          </button>
        </div>
      </div>
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

export default CommentSection;
