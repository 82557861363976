import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TabLayout from "../layout/reportsTabLayout";
import ReportList from "./reports-list";
import ReportDetails from "./report-details";
import ReportSettings from "./report-settings";
import KnowledgeGraphComponent from "./knowledge-graph-component";
import ReportService from "../../service/ReportService";
import DocumentsUsed from "./documents-used";

const Reports = () => {
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [reports, setReports] = useState([]);
  const [isCreatingReport, setIsCreatingReport] = useState(false);
  const [isLoadingReports, setIsLoadingReports] = useState(true);
  const [knowledgeGraphData, setKnowledgeGraphData] = useState(null);
  const [isLoadingKnowledgeGraph, setIsLoadingKnowledgeGraph] = useState(false);
  const navigate = useNavigate();
  const { reportId } = useParams();
  const location = useLocation();
  const isSettingsRoute = location.pathname.endsWith("/settings");

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoadingReports(true);
        const fetchedReports = await ReportService.getAllReports();
        const parsedReports = fetchedReports.map((report) => ({
          report_id: report.report_id,
          name: report.name,
          title: report.title,
          isPinned: report.is_pinned,
          archived: report.archived,
          logo: report.logo,
          template_id: report.template_id,
          completed: report.completed,
          createdAt: report.created_at,
          updatedAt: report.updated_at,
          questions: report.questions || [],
          metadata: report.metadata || {},
        }));
        setReports(parsedReports);
      } catch (error) {
        console.error("Error fetching reports:", error);
        showToast("error", "Error", "Failed to fetch reports");
      } finally {
        setIsLoadingReports(false);
      }
    };
    fetchReports();
    const fetchKnowledgeGraph = async () => {
      try {
        setIsLoadingKnowledgeGraph(true);
        const data = await ReportService.knowledgeGraphForReport({
        });
        setKnowledgeGraphData(data.knowledgeGraph);
      } catch (error) {
        console.error("Error fetching knowledge graph:", error);
        showToast("error", "Error", "Failed to fetch knowledge graph");
      } finally {
        setIsLoadingKnowledgeGraph(false);
      }
    };
    fetchKnowledgeGraph();
  }, []);


  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const createNewReport = async () => {
    setIsCreatingReport(true);
    try {
      const newReportData = {
        name: `Report ${reports.length + 1}`,
        title: `Report ${reports.length + 1}`,
        is_pinned: false,
        report_id: Math.floor(Math.random() * 1000000),
      };
      const createdReport = await ReportService.createReport(newReportData);
      const newReport = {
        report_id: createdReport.report_id,
        name: createdReport.name,
        title: createdReport.title,
        createdAt: createdReport.created_at,
        updatedAt: createdReport.timestamp,
        questions: [],
      };
      setReports([...reports, newReport]);
      navigate(`/portal/reports/${newReport.report_id}`);
      showToast(
        "success",
        "Report Created",
        "New report has been created successfully"
      );
    } catch (error) {
      console.error("Error creating report:", error);
      showToast("error", "Error", "Failed to create new report");
    } finally {
      setIsCreatingReport(false);
    }
  };

  const renderContent = () => {
    if (activeIndex === 1) {
      return (
        <div className="col-span-4 h-full">
          <KnowledgeGraphComponent 
            data={knowledgeGraphData} 
            isLoading={isLoadingKnowledgeGraph} 
          />
        </div>
      );
    }

    if (activeIndex === 2) {
      return (
        <div className="grid col-span-4 w-full h-full">
          <DocumentsUsed reportId={reportId} />
        </div>
      );
    }

    return (
      <div className="grid grid-cols-4 gap-4 h-full">
        <ReportList
          reports={reports}
          activeReportId={reportId}
          onCreateNewReport={createNewReport}
          onUpdateReports={setReports}
          onShowToast={showToast}
          isCreatingReport={isCreatingReport}
          isLoadingReports={isLoadingReports}
        />
        {isSettingsRoute ? (
          <ReportSettings
            reports={reports}
            onUpdateReports={setReports}
            onShowToast={showToast}
          />
        ) : (
          <ReportDetails
            reports={reports}
            reportId={reportId}
            onUpdateReports={setReports}
            onShowToast={showToast}
          />
        )}
      </div>
    );
  };

  return (
    <div className="h-full overflow-hidden">
      <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
        {renderContent()}
        <Toast ref={toast} position="bottom-right" />
      </TabLayout>
    </div>
  );
};

export default Reports;
