import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

function KnowledgeGraph({
  nodes: initialNodes,
  links: initialLinks,
  showControls = true,
}) {
  const svgRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroups, setSelectedGroups] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);

  const [nodes, setNodes] = useState(initialNodes);
  const [links, setLinks] = useState(initialLinks);
  const [isAddingNode, setIsAddingNode] = useState(false);
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [linkSourceNode, setLinkSourceNode] = useState(null);
  const [newNodeData, setNewNodeData] = useState({ id: "", group: "" });

  useEffect(() => {
    // Dynamically get available groups from nodes data
    const groups = [...new Set(nodes.map((node) => node.group))];
    setAvailableGroups(groups);
    let projectionLine = null;

    // Initialize selected groups if empty
    if (Object.keys(selectedGroups).length === 0) {
      const initialGroups = groups.reduce(
        (acc, group) => ({
          ...acc,
          [group]: true,
        }),
        {}
      );
      setSelectedGroups(initialGroups);
      return;
    }

    // Filter nodes based on search and group filters
    const filteredNodes = nodes.filter((node) => {
      const matchesSearch = node.id
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const groupIsSelected = selectedGroups[node.group];
      return matchesSearch && groupIsSelected;
    });

    // Filter links to only include connections between filtered nodes
    const filteredLinks = links.filter((link) => {
      const sourceExists = filteredNodes.find(
        (node) => node.id === link.source
      );
      const targetExists = filteredNodes.find(
        (node) => node.id === link.target
      );
      return sourceExists && targetExists;
    });

    const filteredData = {
      nodes: filteredNodes.map((node) => ({ ...node })),
      links: filteredLinks.map((link) => ({ ...link })),
    };

    const svg = d3.select(svgRef.current);

    // Add click handler to svg to clear selection
    svg.on("click", () => {
      setSelectedNode(null);
      setSelectedLink(null);
    });
    svg.selectAll("*").remove(); // Clear previous content

    const width = svgRef.current.clientWidth || 800;
    const height = svgRef.current.clientHeight || 600;

    // Add zoom and pan functionality
    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 5])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });
    svg.call(zoom);

    // Create a container group
    const g = svg.append("g");

    // Define color scale
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // Create simulation
    const simulation = d3
      .forceSimulation(filteredData.nodes)
      .force(
        "link",
        d3
          .forceLink(filteredData.links)
          .id((d) => d.id)
          .distance(150)
      )
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // Draw links
    const linkGroup = g
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("g")
      .data(filteredData.links)
      .enter()
      .append("g");

    const link = linkGroup
      .append("line")
      .attr("stroke-width", 2)
      .on("click", (event, d) => {
        event.stopPropagation();
        setSelectedLink(d);
        setSelectedNode(null);
      })
      .style("stroke", (d) => (d === selectedLink ? "#ff0000" : "#999"))
      .style("stroke-width", (d) => (d === selectedLink ? 4 : 2))
      .on("contextmenu", (event, d) => {
        event.preventDefault();
        setLinks((prevLinks) => prevLinks.filter((l) => l !== d));
      });

    // Add relation labels on links
    const linkLabel = linkGroup
      .append("text")
      .attr("dy", -5)
      .attr("text-anchor", "middle")
      .text((d) => d.relation)
      .style("font-size", "10px")
      .style("fill", "#666")
      .style("pointer-events", "none");

    // Draw nodes
    const node = g
      .append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(filteredData.nodes, (d) => d.id)
      .enter()
      .append("circle")
      .attr("r", 10)
      .attr("fill", (d) => color(d.group))
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      )
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`${d.id}<br/>(${d.group})`)
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      })
      .on("click", (event, d) => {
        if (isAddingLink) {
          if (!linkSourceNode) {
            setLinkSourceNode(d.id);
          } else {
            const newLink = {
              source: linkSourceNode,
              target: d.id,
              relation: "related",
            };
            setLinks((prevLinks) => [...prevLinks, newLink]);
            setLinkSourceNode(null);
            setIsAddingLink(false);
          }
        } else {
          event.stopPropagation();
          setSelectedNode(d.id);
          setSelectedLink(null);
          const transform = d3.zoomIdentity
            .translate(width / 2, height / 2)
            .scale(2)
            .translate(-d.x, -d.y);
          svg.transition().duration(750).call(zoom.transform, transform);
        }
      })
      .on("contextmenu", (event, d) => {
        event.preventDefault();
        setNodes((prevNodes) => prevNodes.filter((node) => node !== d));
        setLinks((prevLinks) =>
          prevLinks.filter(
            (link) => link.source !== d.id && link.target !== d.id
          )
        );
      });

    if (isAddingLink && linkSourceNode) {
      const sourceNode = filteredData.nodes.find(
        (node) => node.id === linkSourceNode
      );

      if (sourceNode) {
        projectionLine = g
          .append("line")
          .attr("class", "projection-line")
          .attr("stroke", "#000")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "5,5")
          .attr("x1", sourceNode.x)
          .attr("y1", sourceNode.y)
          .attr("x2", sourceNode.x)
          .attr("y2", sourceNode.y);

        const mousemove = (event) => {
          const point = d3.pointer(event, g.node());
          const [x, y] = point;
          projectionLine.attr("x2", x).attr("y2", y);
        };

        svg.on("mousemove", mousemove);
      }
    }

    // Add node labels
    const label = g
      .append("g")
      .selectAll("text")
      .data(filteredData.nodes)
      .enter()
      .append("text")
      .attr("dy", -15)
      .attr("text-anchor", "middle")
      .text((d) => d.id)
      .style("font-size", "12px");

    // Add tooltips
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("text-align", "center")
      .style("padding", "6px")
      .style("font", "12px sans-serif")
      .style("background", "lightsteelblue")
      .style("border", "0px")
      .style("border-radius", "8px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    // Reset zoom on double click
    svg.on("dblclick", () => {
      svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
    });

    // Define simulation tick behavior
    simulation.on("tick", () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      // Update relation label positions
      linkLabel.attr("x", (d) => (d.source.x + d.target.x) / 2)
        .attr("y", (d) => (d.source.y + d.target.y) / 2);

      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      label.attr("x", (d) => d.x).attr("y", (d) => d.y);
    });

    function getNodeById(id) {
      if (typeof id === "object") return id;
      return filteredData.nodes.find((node) => node.id === id);
    }

    // Drag functions
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Cleanup on unmount
    return () => {
      simulation.stop();
      tooltip.remove();
      if (projectionLine) {
        projectionLine.remove();
      }
      svg.on("mousemove", null);
    };
  }, [
    searchTerm,
    selectedGroups,
    nodes,
    links,
    isAddingLink,
    linkSourceNode,
    selectedNode,
    selectedLink,
  ]);

  const handleGroupToggle = (group) => {
    setSelectedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleAddNode = () => {
    setIsAddingNode(true);
  };

  const handleNodeSubmit = () => {
    if (newNodeData.id && newNodeData.group) {
      setNodes((prevNodes) => [...prevNodes, { ...newNodeData }]);
      setNewNodeData({ id: "", group: "" });
      setIsAddingNode(false);
    }
  };

  const handleAddLink = () => {
    setIsAddingLink(true);
    setLinkSourceNode(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        if (selectedNode) {
          setNodes((prevNodes) =>
            prevNodes.filter((node) => node.id !== selectedNode)
          );
          setLinks((prevLinks) =>
            prevLinks.filter(
              (link) =>
                link.source !== selectedNode && link.target !== selectedNode
            )
          );
          setSelectedNode(null);
        }
        if (selectedLink) {
          setLinks((prevLinks) =>
            prevLinks.filter((link) => link !== selectedLink)
          );
          setSelectedLink(null);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedNode, selectedLink]);

  return (
    <div className="relative h-[calc(100vh-6rem)]">
      {/* SVG Visualization */}
      <svg
        ref={svgRef}
        className="w-full h-full rounded-2xl border border-gray-200 bg-white"
      ></svg>

      {/* Controls overlay */}
      {showControls && (
        <div className="absolute top-4 right-4 z-10">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-2 flex items-center gap-2">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-48 h-8 pl-8 pr-3 rounded-md border border-gray-200 text-sm placeholder-gray-500 focus:border-blue-600 focus:ring-1 focus:ring-blue-600 bg-gray-50"
              />
              <svg
                className="w-4 h-4 text-gray-400 absolute left-2.5 top-1/2 -translate-y-1/2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>

            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md inline-flex items-center gap-1.5 transition-colors"
              >
                <span>Filter</span>
                <svg
                  className={`w-3.5 h-3.5 transition-transform ${isFilterOpen ? "rotate-180" : ""}`}
                  fill="none" 
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isFilterOpen && (
                <div className="absolute top-full right-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 overflow-hidden">
                  {availableGroups.map((group) => (
                    <button
                      key={group}
                      onClick={() => handleGroupToggle(group)}
                      className={`w-full px-4 bg-white py-2 text-sm text-left hover:bg-gray-100 transition-colors flex items-center justify-between ${
                        selectedGroups[group] ? "text-blue-600" : "text-gray-700"
                      }`}
                    >
                      {group}
                      {selectedGroups[group] && (
                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      )}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="flex items-center gap-1 border-l border-gray-200 pl-2">
              <button 
                onClick={handleAddNode} 
                className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md transition-colors inline-flex items-center gap-1"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
                Node
              </button>
              <button 
                onClick={handleAddLink} 
                className="px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-100 rounded-md transition-colors inline-flex items-center gap-1"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                </svg>
                {isAddingLink ? "Cancel Link" : "Link"}
              </button>
            </div>
            {isAddingLink && linkSourceNode && (
              <span className="text-sm text-gray-500">Select target</span>
            )}
          </div>
        </div>
      )}

      {/* Add Node Modal */}
      {isAddingNode && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Add New Node</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Node ID
              </label>
              <input
                type="text"
                value={newNodeData.id}
                onChange={(e) =>
                  setNewNodeData({ ...newNodeData, id: e.target.value })
                }
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Group
              </label>
              <input
                type="text"
                value={newNodeData.group}
                onChange={(e) =>
                  setNewNodeData({ ...newNodeData, group: e.target.value })
                }
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setIsAddingNode(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-xl hover:bg-gray-600 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleNodeSubmit}
                className="px-4 py-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition"
              >
                Add Node
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <div className="px-4 py-2 bg-gray-900/80 backdrop-blur rounded-full">
          <span className="text-xs font-medium text-white">
            Double click to reset zoom
          </span>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeGraph;
