import axios from "axios";
import { REPORT_API_BASE_URL } from "../utils/config";

const ReportService = {
  createReport: async (reportData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/create_report`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAllReports: async (orgId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${REPORT_API_BASE_URL}/reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.reports;
    } catch (error) {
      throw error;
    }
  },

  getReportById: async (reportId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${REPORT_API_BASE_URL}/${reportId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.report;
    } catch (error) {
      throw error;
    }
  },
  visualizeReport: async ({ report_id, question_id, ...reportData }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/visualize`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  knowledgeGraphForQuestion: async ({
    report_id,
    question_id,
    ...reportData
  }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/knowledge-graph`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  knowledgeGraphForReport: async ({ }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/knowledge-graph`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  documentsUsed: async (paginationParams) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/documents-used`,
        {...paginationParams},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  uploadDocumentsUsed: async (file) => {
    const token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(
        `${REPORT_API_BASE_URL}/documents-used/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteDocumentsUsed: async (s3Key) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${REPORT_API_BASE_URL}/documents-used/${s3Key}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  tablesUsedForQuestion: async ({ report_id, question_id, ...reportData }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/tables-used`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  regenrateAnswerForQuestion: async ({ report_id, question_id, regenerateFeedback }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/regenerate-answer`,
        {regenerateFeedback},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },
  updateQuestionAssignee: async ({ report_id, question_id, assignee }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/assign`,
        { assignee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },

  updateReport: async (reportId, reportData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${REPORT_API_BASE_URL}/${reportId}`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteReport: async (reportId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${REPORT_API_BASE_URL}/${reportId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addQuestion: async (reportId, questionData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${reportId}/add_question`,
        questionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },
  updateQuestion: async (reportId, questionId, questionData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}`,
        questionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteQuestion: async (reportId, questionId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCommentsForQuestion: async (reportId, questionId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}/comments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.comments;
    } catch (error) {
      throw error;
    }
  },
  addComment: async (reportId, questionId, commentData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}/comments`,
        commentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ReportService;
