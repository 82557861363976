import React from 'react';
import { AiOutlineExport, AiOutlinePlus } from 'react-icons/ai';
import { formatDate, formatLastUpdated } from '../../util/dateUtils';

const TemplateOne = ({
  report,
  showLogoUpload,
  setShowLogoUpload,
  isUploadingLogo,
  handleLogoUpload,
  handleTitleChange,
}) => (
  <>
    <div
      className="relative mb-4 group"
      onMouseEnter={() => setShowLogoUpload(true)}
      onMouseLeave={() => setShowLogoUpload(false)}
    >
      {report.logo ? (
        <img
          src={report.logo}
          alt="Report Logo"
          className="h-16 w-16 object-contain cursor-pointer hover:opacity-75 transition-opacity"
          onClick={() => document.getElementById('logo-upload').click()}
        />
      ) : (
        <div
          className="h-16 w-16 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
          onClick={() => document.getElementById('logo-upload').click()}
        >
          <AiOutlinePlus className="text-gray-400 text-xl" />
        </div>
      )}
      <input
        type="file"
        id="logo-upload"
        className="hidden"
        accept="image/*"
        onChange={(e) => handleLogoUpload(e.target.files[0])}
        disabled={isUploadingLogo}
      />
      {showLogoUpload && (
        <div className="absolute left-0 -bottom-6 text-xs text-gray-500">
          {isUploadingLogo ? 'Uploading...' : 'Click to add logo'}
        </div>
      )}
    </div>
    <div
      className="relative"
    >
      <input
        type="text"
        value={report.title}
        onChange={(e) => handleTitleChange(e.target.value)}
        className="font-bold text-4xl bg-transparent border-none focus:outline-none w-full mb-2"
        placeholder="Untitled"
      />
    </div>
    <div className="flex items-center text-sm text-gray-500">
      <span className="mr-2">Created: {formatDate(report.created_at)}</span>
      <span>•</span>
      <span className="ml-2">Last updated: {formatLastUpdated(report.updated_at)}</span>
    </div>
  </>
);

export default React.memo(TemplateOne);