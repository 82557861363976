import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { AiOutlineFile, AiOutlineStar, AiOutlineDownload, AiOutlineDelete, AiOutlineUpload } from "react-icons/ai";
import ReportService from "../../service/ReportService";

const DocumentsUsed = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'modifiedAt',
    direction: 'desc'
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [selectedDocs, setSelectedDocs] = useState(new Set());
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const fileInputRef = useRef(null);
  const PER_PAGE = 10;

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await ReportService.documentsUsed({
          page,
          per_page: PER_PAGE
        });
        setDocuments(response.documentsUsed || []);
        setTotalPages(Math.ceil(response.total_documents / PER_PAGE));
        setTotalDocuments(response.total_documents);
      } catch (error) {
        console.error("Error fetching documents:", error);
        setError("Failed to fetch documents. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocuments();
  }, [page]);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      setSelectedDocs(new Set()); // Clear selection on page change
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDocs(new Set(sortedDocuments.map(doc => doc.id)));
    } else {
      setSelectedDocs(new Set());
    }
  };

  const handleSelectDoc = (docId) => {
    const newSelected = new Set(selectedDocs);
    if (newSelected.has(docId)) {
      newSelected.delete(docId);
    } else {
      newSelected.add(docId);
    }
    setSelectedDocs(newSelected);
  };

  const handleDownloadSelected = async () => {
    try {
      const selectedDocuments = sortedDocuments.filter(doc => selectedDocs.has(doc.id));
      
      // Download each selected document
      const downloads = selectedDocuments.map(async (doc) => {
        const response = await fetch(doc.link);
        const blob = await response.blob();
        
        // Create temporary link and trigger download
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = doc.name; // Use document name as filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      });

      // Wait for all downloads to complete
      await Promise.all(downloads);
      
    } catch (error) {
      console.error("Error downloading documents:", error);
      // You may want to show an error message to the user here
    }
  };

  const handleDeleteSelected = async () => {
    if (isDeleting) return;

    try {
      setIsDeleting(true);
      const selectedDocuments = sortedDocuments.filter(doc => selectedDocs.has(doc.id));
      
      // Delete each selected document
      const deletions = selectedDocuments.map(async (doc) => {
        await ReportService.deleteDocumentsUsed(doc.id);
      });

      await Promise.all(deletions);

      // Refresh documents list
      const response = await ReportService.documentsUsed({
        page,
        per_page: PER_PAGE
      });
      setDocuments(response.documentsUsed || []);
      setTotalPages(Math.ceil(response.total_documents / PER_PAGE));
      setTotalDocuments(response.total_documents);
      setSelectedDocs(new Set()); // Clear selection after delete

    } catch (error) {
      console.error("Error deleting documents:", error);
      setError("Failed to delete documents. Please try again later.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      const response = await ReportService.uploadDocumentsUsed(file);
      
      if (response.acknowledgement === "success") {
        // Refresh the documents list
        const documentsResponse = await ReportService.documentsUsed({
          page,
          per_page: PER_PAGE
        });
        setDocuments(documentsResponse.documentsUsed || []);
        setTotalPages(Math.ceil(documentsResponse.total_documents / PER_PAGE));
        setTotalDocuments(documentsResponse.total_documents);
      } else {
        setError(response.message || "Failed to upload document");
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      setError("Failed to upload document. Please try again later.");
    } finally {
      setIsUploading(false);
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const sortedDocuments = [...documents].sort((a, b) => {
    if (sortConfig.key === 'popularity') {
      return sortConfig.direction === 'asc' 
        ? a.popularity - b.popularity
        : b.popularity - a.popularity;
    }
    
    if (sortConfig.key === 'size') {
      return sortConfig.direction === 'asc'
        ? a.size - b.size
        : b.size - a.size;
    }

    return sortConfig.direction === 'asc'
      ? a[sortConfig.key].localeCompare(b[sortConfig.key])
      : b[sortConfig.key].localeCompare(a[sortConfig.key]);
  });

  const renderSkeleton = () => (
    <div className="animate-pulse">
      <div className="space-y-3">
        {[...Array(PER_PAGE)].map((_, idx) => (
          <div key={idx} className="grid grid-cols-6 gap-4">
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
          </div>
        ))}
      </div>
    </div>
  );

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-red-500 text-center">
          <p>{error}</p>
          <button 
            onClick={() => setPage(page)}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white h-full rounded-lg shadow flex flex-col">
      <div className="border-b px-6 py-4 flex items-center justify-between sticky top-0 bg-white z-20">
        <div className="flex items-center space-x-4">
          {selectedDocs.size > 0 && (
            <>
              <button
                onClick={handleDownloadSelected}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <AiOutlineDownload className="mr-2 h-4 w-4" />
                Download ({selectedDocs.size})
              </button>
              <button
                onClick={handleDeleteSelected}
                disabled={isDeleting}
                className="inline-flex items-center px-3 py-1.5 border border-red-300 rounded-md text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <AiOutlineDelete className="mr-2 h-4 w-4" />
                {isDeleting ? 'Deleting...' : `Delete (${selectedDocs.size})`}
              </button>
            </>
          )}
        </div>
        <div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
          />
          <button
            onClick={handleUpload}
            disabled={isUploading}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <AiOutlineUpload className="mr-2 h-4 w-4" />
            {isUploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </div>
      
      <div className="overflow-x-auto flex-1">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th scope="col" className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={selectedDocs.size === sortedDocuments.length && sortedDocuments.length > 0}
                  onChange={handleSelectAll}
                />
              </th>
              {[
                { key: 'name', label: 'Name' },
                { key: 'type', label: 'Type' },
                { key: 'modifiedAt', label: 'Modified At' },
                { key: 'size', label: 'Size' },
                { key: 'popularity', label: 'Popularity' }
              ].map(({ key, label }) => (
                <th 
                  key={key}
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 select-none"
                  onClick={() => handleSort(key)}
                >
                  <div className="flex items-center space-x-1">
                    <span>{label}</span>
                    {sortConfig.key === key && (
                      <span className="text-gray-400">
                        {sortConfig.direction === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoading ? (
              <tr>
                <td colSpan="6" className="px-6 py-4">
                  {renderSkeleton()}
                </td>
              </tr>
            ) : (
              sortedDocuments.map((doc, index) => (
                <tr 
                  key={index}
                  className={`group hover:bg-gray-50 transition-colors duration-150 ${
                    selectedDocs.has(doc.id) ? 'bg-blue-50' : ''
                  }`}
                >
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      checked={selectedDocs.has(doc.id)}
                      onChange={() => handleSelectDoc(doc.id)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center min-w-0">
                        <AiOutlineFile className="flex-shrink-0 h-4 w-4 text-gray-400" />
                        <span className="ml-2 text-sm text-gray-900 truncate">
                          {doc.name}
                        </span>
                      </div>
                      <a 
                        href={doc.link}
                        target="_blank"
                        rel="noopener noreferrer" 
                        className="ml-2 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                        title="Open in new tab"
                      >
                        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      </a>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {doc.type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(doc.modifiedAt), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatFileSize(doc.size)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <AiOutlineStar className="h-4 w-4 text-yellow-400" />
                      <span className="ml-1 text-sm text-gray-900">{doc.popularity}</span>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="px-6 py-3 border-t flex items-center justify-between bg-white sticky bottom-0">
        <div className="text-sm text-gray-700">
          Showing {((page - 1) * PER_PAGE) + 1} to {Math.min(page * PER_PAGE, totalDocuments)} of {totalDocuments} documents
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1 || isLoading}
            className="px-3 py-1.5 border rounded text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages || isLoading}
            className="px-3 py-1.5 border rounded text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsUsed;
