import React, { useCallback } from 'react';
import { formatDate } from '../../util/dateUtils';
import { debounce } from 'lodash';

const TemplateTwo = ({ report, updateReport }) => {
    // Initialize metadata with defaults from the image
    const metadata = {
      jurisdiction: 'STATE OF CALIFORNIA',
      commission: 'PUBLIC UTILITIES COMMISSION',
      rulemaking_id: 'Rulemaking 13-11-005',
      order_title: 'Order Instituting Rulemaking Concerning Energy Efficiency Rolling Portfolios, Policies, Programs, Evaluation, and Related Issues.',
      motion_title: 'MOTION OF SOUTHERN CALIFORNIA EDISON COMPANY (U 338-E) FOR LEAVE TO FILE THE CONFIDENTIAL VERSION OF THE SEMI-ANNUAL INDEPENDENT EVALUATOR REPORT ON THE THIRD-PARTY SOLICITATION PROCESS UNDER SEAL',
      attorneys: [
        {
          name: 'ELLEN A. BERMAN',
          firm: 'EB Energy Law, Inc.',
          address: '50 W. Hillcrest Drive Suite 218\nThousand Oaks, CA 91360',
          phone: '(310) 663-7478',
          email: 'ellen@ebenergylaw.com',
          title: 'Attorney for\nSOUTHERN CALIFORNIA EDISON COMPANY'
        },
        {
          name: 'ANNA VALDBERG',
          firm: 'Southern California Edison Company',
          address: '2244 Walnut Grove Avenue\nRosemead, California 91770',
          phone: '(626) 302-1058',
          email: 'Anna.Valdberg@sce.com',
          title: 'Attorney for\nSOUTHERN CALIFORNIA EDISON COMPANY'
        }
      ],
      ...report.metadata 
    };
    
      const handleMetadataChange = (key, value) => {
        const updatedReport = {
          ...report,
          metadata: {
            ...metadata,
            [key]: value
          }
        };
        updateReport(updatedReport);
      };
    
      const handleAttorneyChange = (index, field, value) => {
        const updatedAttorneys = [...metadata.attorneys];
        updatedAttorneys[index] = { 
          ...updatedAttorneys[index], 
          [field]: value 
        };
        
        const updatedReport = {
          ...report,
          metadata: {
            ...report.metadata,
            attorneys: updatedAttorneys
          }
        };
        updateReport(updatedReport);
      };
    
  
    return (
      <div className="max-w-4xl mx-auto text-center p-8">
        {/* Header Section */}
        <div className="mb-16 space-y-6">
          <div className="uppercase font-bold text-lg">
            <div contentEditable 
                 suppressContentEditableWarning
                 onBlur={(e) => handleMetadataChange('commission_header', e.target.innerText)}
                 className="focus:outline-none">
              BEFORE THE {metadata.commission} OF THE
            </div>
          </div>
          <div className="uppercase font-bold text-lg">
            <div contentEditable 
                 suppressContentEditableWarning
                 onBlur={(e) => handleMetadataChange('jurisdiction', e.target.innerText)}
                 className="focus:outline-none">
              {metadata.jurisdiction}
            </div>
          </div>
        </div>
  
        {/* Order Title and Rulemaking Section */}
        <div className="grid grid-cols-2 gap-8 mb-16">
          <div className="text-left border-l-2 border-black pl-4">
            <div contentEditable 
                 suppressContentEditableWarning
                 onBlur={(e) => handleMetadataChange('order_title', e.target.innerText)}
                 className="focus:outline-none">
              {metadata.order_title}
            </div>
          </div>
          <div className="text-right">
            <div contentEditable 
                 suppressContentEditableWarning
                 onBlur={(e) => handleMetadataChange('rulemaking_id', e.target.innerText)}
                 className="focus:outline-none">
              {metadata.rulemaking_id}
            </div>
          </div>
        </div>
  
        {/* Motion Title */}
        <div className="mb-20">
          <div contentEditable 
               suppressContentEditableWarning
               onBlur={(e) => handleMetadataChange('motion_title', e.target.innerText)}
               className="uppercase font-bold text-center focus:outline-none whitespace-pre-line">
            {metadata.motion_title}
          </div>
        </div>
  
        {/* Attorneys Section */}
        <div className="grid grid-cols-2 gap-16 text-left mb-16">
          {metadata.attorneys?.map((attorney, index) => (
            <div key={index} className="space-y-2">
              <div contentEditable 
                   suppressContentEditableWarning
                   onBlur={(e) => {
                     const updatedAttorneys = [...metadata.attorneys];
                     updatedAttorneys[index] = { ...attorney, name: e.target.innerText };
                     handleMetadataChange('attorneys', updatedAttorneys);
                   }}
                   className="font-bold focus:outline-none">
                {attorney.name}
              </div>
              <div contentEditable 
                   suppressContentEditableWarning
                   onBlur={(e) => {
                     const updatedAttorneys = [...metadata.attorneys];
                     updatedAttorneys[index] = { ...attorney, firm: e.target.innerText };
                     handleMetadataChange('attorneys', updatedAttorneys);
                   }}
                   className="focus:outline-none">
                {attorney.firm}
              </div>
              <div contentEditable 
                   suppressContentEditableWarning
                   onBlur={(e) => handleAttorneyChange(index, 'name', e.target.innerText)}

                   className="focus:outline-none whitespace-pre-line">
                {attorney.address}
              </div>
              <div contentEditable 
                   suppressContentEditableWarning
                   onBlur={(e) => handleAttorneyChange(index, 'name', e.target.innerText)}

                   className="focus:outline-none">
                Telephone: {attorney.phone}
              </div>
              <div contentEditable 
                   suppressContentEditableWarning
                   onBlur={(e) => handleAttorneyChange(index, 'name', e.target.innerText)}

                   className="focus:outline-none">
                Email: {attorney.email}
              </div>
              <div contentEditable 
                   suppressContentEditableWarning
                   onBlur={(e) => handleAttorneyChange(index, 'name', e.target.innerText)}

                   className="focus:outline-none whitespace-pre-line">
                {attorney.title}
              </div>
            </div>
          ))}
        </div>
  
        {/* Date Section */}
        <div className="text-left">
          <div>Dated: {formatDate(report.created_at, 'MMMM D, YYYY')}</div>
        </div>
      </div>
    );
  };

export default React.memo(TemplateTwo);